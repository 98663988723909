.profile-container {
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  background: rgba(35, 31, 32, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.7);
  border-radius: 20px;
}

.profile-info {
  
  align-items: center;
  justify-content: center !important;
  margin-left: 3rem;
  /* font-size: 1.5rem; */
  
}

.profile-signature {
  background-color: #f1f1f1;
  border-radius: 12px;
}

@media (max-width: 600px) {
  .profile-container {
    padding: 20px;
  }
}
