.registration-stripe-section {
  background: rgba(10, 10, 10, 0.9);
  backdrop-filter: blur(15px) saturate(150%);
  -webkit-backdrop-filter: blur(15px) saturate(150%);
  overflow: hidden;
  padding: 2rem 0;
}

.reg-stripe-left-img,
.reg-stripe-right-img {
  height: auto;
  width: 9em;
}

.reg-stripe-content {
  display: flex;
  flex-direction: row; /* Align content in a row */
  align-items: center;
  justify-content: space-around; /* Space between items */
  color: whitesmoke;
  text-align: center;
  margin-top: 2rem;
}

.stripe-number {
  color: #ee0000;
  font-size: 2rem; 
}

.stripe-text {
  font-size: 2rem;
}


.d-flex.justify-content-end {
  display: flex;
  align-items: center;
  justify-content: flex-end; 
}

.span-index {
  margin-left: 1rem;
}

.strip-text{
  font-size: 2em !important;
  color: white;
  font-family: "Gellix" !important;
}

@media(max-width:991px){
  .strip-text{
    font-size: 1.3em !important;
    color: white;
    font-family: "Gellix" !important;
  }
}

@media (max-width: 1024px) {
  .reg-stripe-left-img,
  .reg-stripe-right-img {
    width: 6em; 
  }

  .stripe-number,
  .stripe-text {
    font-size: 20px !important; 
  }

  .registration-stripe-section {
    padding: 1rem 0; 
  }

  .reg-stripe-content {
    flex-direction: row; 
    margin-top: 0.5rem; 
    justify-content: space-around; 

  }

  .span-index {
    margin-left: 5px !important;
  }
}

@media (max-width: 576px) {
  .reg-stripe-left-img,
  .reg-stripe-right-img {
    width: 3em;
  }

  .strip-text{
    font-size: 0.8em !important;
    color: white;
  }

  .stripe-number,
  .stripe-text {
    font-size: 10px !important; 
    
  }

  .stripe-text {
    margin-left: 3px !important;
  }

  .reg-stripe-content {
    flex-direction: row;
    margin-top: 0.5rem; 
    justify-content: space-around; 
  }

  .span-index  {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }
}
