/* Responsive Navigation Bar */
.navigation-bar {
    background: #ffffff40;
    justify-content: space-between;
    z-index: 1;
    box-shadow: 0px 0px 43px 0px #8a57ea;
  }
  
  .kama-logo {
    height: 60px;
  }
  
  .ifx-logo {
    height: 38px;
    margin-right: 20px; /* Add space between IFX logo and logout icon */
  }
  
  .logout-section {
    display: flex;
    align-items: center;
    padding-right: 10px;
    cursor: pointer;
  }
  
  
  
  /* Responsiveness */
  @media (max-width: 768px) {
    .kama-logo {
      height: 50px;
    }
  
    .ifx-logo {
      height: 32px;
      margin-right: 10px; /* Reduced margin for mobile */
    }
  }
  