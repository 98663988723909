.admin-edit-registeration-button {
  text-decoration: none !important;
  display: block;
  width: 70%;
  padding: 15px 20px;
  margin: 20px auto;
  font-size: 1.2rem;
  color: #ffffff;
  background-color: #6000d9;
  border: none;
  border-radius: 50px;
  box-shadow: 0 8px 15px rgba(0, 123, 255, 0.2);
  transition: all 0.3s ease-in-out;
  text-align: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}


.admin-edit-reg-index-con {
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  background: rgba(35, 31, 32, 0.719) !important;
  border: 1px solid rgba(255, 255, 255, 1) !important; 
}

.admin-edit-reg-index-con input , .admin-edit-reg-index-con select {
  background-color: rgba(26, 25, 25, 0.826);
  color: white;
  height: 3rem;
  width: 100%;
  font-size: 1rem;
  padding-left: 1rem;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: none;
  border-radius: .5rem;

}

.admin-edit-reg-index-con input:focus , .admin-edit-reg-index-con select:focus {
  border: none !important;
}

.admin-edit-reg-index-con::-webkit-scrollbar {
  display: none;
}
  .admin-edit-registeration-button:hover {
    background-color: #6000d9;
    box-shadow: 0 15px 20px rgba(0, 123, 255, 0.4);
    transform: translateY(-5px);
  }

  .admin-pannel-edit-registration {
    background-color:#0000;
  }

  .admin-edit-form-label {
    color: #FFFF;
    margin-bottom: .5rem;
  }


  .admin-edit-reg-index-con input[type="number"]::-webkit-inner-spin-button,
  .admin-edit-reg-index-con input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.admin-edit-reg-index-con input[type="number"] {
  -moz-appearance: textfield;
}

  