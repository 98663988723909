

.registration-contact{
    background: rgba(35, 31, 32, 0.365);
    border-radius: 15px;
    padding: 20px;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); 
    border: 1px solid rgba(255, 255, 255, 0.3); 
    overflow: auto;
    opacity: 100%;
    color: #FFFF;
}

.contact-heading{
    color: black;
    font-weight: bold;
    font-family: 'Gellix' !important;
}


.social-media-icons{
    display: flex;
    justify-content: space-between;
}


.reg-contact-icons {
    background-color: #FFF;
    width: 2rem;
    height: 2rem;
    padding: 0.2rem;
    color: #000;
    border-radius: 5px;
     font-size: 1.5rem;
    transition: all 0.3s ease;
}

.reg-contact-icons:hover {
  background: #000;
  color: #fff;
}

.contact-phone-icon{
    text-decoration: none;
    color: #FFF;
}