.reg-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  padding-bottom: 2em !important;
}


.reg-glass-container {
  border-radius: 15px;
  padding: 20px;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); 
  background: rgba(35,31,32, 0.365);
  border: 2px solid rgba(255, 255, 255, 1); 
  width: 700px;
  height: auto;
  text-align: center;
  overflow: auto;
  opacity: 100%;
}

.reg-glass-container::-webkit-scrollbar {
  display: none; 
}

.reg-full-width-full-name {
   grid-column: span 2;
   width: 41.4rem;
}

#glass-registration-form {
 
  display: grid;
  grid-template-columns: repeat(2 , 1fr);
  gap: 20px;
  margin: 0 auto;
}

.reg-glass-form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.reg-phone-input-group {
  display: flex;
  align-items: center;
  gap: 10px; 
  
}

.country-name-input{
  height: 3em;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.237);
  backdrop-filter: blur(10%);
  -webkit-backdrop-filter: blur(10%);
  color: #fff;
  font-size: 1rem;
  width: 100%;
}

.country-name-input option{
  background-color: rgba(192, 193, 192, 0.635);
 backdrop-filter: blur(10%);
 -webkit-backdrop-filter: blur(10%);
  color: #333333; 
  padding: 10px; 
}

.reg-phone-input-group select {
  height: 3em;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.237);
  backdrop-filter: blur(10%);
  -webkit-backdrop-filter: blur(10%);
  color: #fff;
  font-size: 1rem;
  width: 4.5rem; 
}

.reg-phone-input-group select:focus, .country-name-input,
.reg-phone-input-group input:focus , .reg-phone-input-group section option {
  border-color: #FFFF ,; 
  outline: none;
  background-color: rgba(255, 255, 255, 0.237);
  backdrop-filter: blur(10%);
  -webkit-backdrop-filter: blur(10%);
}

.reg-phone-input-group select option  {
 background-color: rgba(192, 193, 192, 0.635);
 backdrop-filter: blur(10%);
 -webkit-backdrop-filter: blur(10%);
  color: #333333; 
  padding: 10px; 
} 

.reg-one {
  grid-column: 2;
  grid-row: 3/5;

}

.reg-four {
  grid-column: span 2; 
}



.reg-glass-form-group label {
  margin-bottom: 5px;
  font-size: 1rem;
  color: #fff;
  text-align: start;
}

.reg-glass-form-group input {
  font-weight: bolder;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  font-size: 1rem;
  background-color: rgba(255, 255, 255, 0.237); 
  backdrop-filter: blur(10%);
  -webkit-backdrop-filter: blur(10%);
  outline: none; 
  width: auto;
  height: 3rem;
  
}

.reg-glass-form-group #user_phone {
  width: 15rem;
}

.reg-signaturePad:checked {
    border: 1px solid #FFFFFF; 
}


.reg-signature-pad {
    border: 1px solid ; 
    height: 9rem;
    width: 18em;
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
}

.reg-signature-canvas {
  width: 20rem;
  height: 5.5rem;
}


.reg-signature-clear-button {
  width: 100%;
  height: 3rem;
  border-radius:5px ;
  background-color: #ee0000;
  border: none;
  color: #FFF;
}

.reg-signature-clear-button:hover {
  border: 1px solid #FFFF;
}
/* .reg-three {
    grid-column: span 2;
    display: flex;
    justify-content: space-between; 
    align-items: center;           
    gap: 10px;                     
} */

.reg-three {
  width: 100% !important;
  display: flex;
  justify-content: center;
}



.reg-signIn-terms-box {
    padding: 10px; 
  }

.reg-signature {
    padding: 10px; 

}


.reg-glass-form-group input:focus {
  border: none; 
  box-shadow: none; 
  color: #f5f5f5;
}

#webcam-container {
  margin-top: 15px;
}

.reg-user_image_input {
  display: grid;
  grid-template-columns: 1fr 1fr; 
  gap: 10px; 
}

.reg-upload_image {
  grid-row: inherit;
  background-color: rgba(255, 255, 255, 0.237);
  padding: 10px;
  text-align: center;
  backdrop-filter: blur(10%);
  -webkit-backdrop-filter: blur(10%);
  border: 1px solid #ccc;
}

.reg-selfie_upload {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.reg-file_upload {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px ;
}

.reg-upload_image p {
  margin: 1;
  font-size: 14px;
  color: #FFF;
  text-align:center;
}
.reg-camera_button:hover p  {
 color: #6000d2;
}
.reg-file-upload-label:hover p  {
 color: #6000d2;
}

.reg-icon {
    margin-top: 2rem;
    font-size: 30px;
    color: #fff;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
    display: block;
  
}

.reg-icon:hover {

  color: #6000d2;
}

.reg-preview-container {
  position: relative;
  width: 20rem ;
  height: 250px;
  background-color: rgba(255, 255, 255, 0.1); 
  border: 2px solid #ccc;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px;
}

.reg-placeholder-text {
  color: #fff;
  font-size: 1rem;
}

.reg-preview-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.reg-capture-button {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #008633;
  color: #FFFF;
  padding: 8px 15px;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  width: 8rem;

}

.reg-retake-button {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ee0000;
  color: #FFFF;
  padding: 8px 15px;
  border: none;
  border-radius: 5px;
  font-weight: bold
}

.reg-retake-button:hover {
  position: absolute;
  bottom: 10px;
  left: 50%;

  background-color: #ee0000;
  color: #FFFF;
  padding: 8px 15px;
  border: 1px solid #fff;
  border-radius: 5px;
  font-weight: bold
}



.reg-capture-button:hover {
  background-color: #FFFF;
  position: absolute;
  bottom: 10px;
  left: 50%;
  background-color: #008633;
  color: #FFFF;
  padding: 8px 15px;
  border-radius: 5px;
  font-weight: bold;
  border: 1px solid #FFFF;
}

.reg-take-button {
  display: inline-block;
  margin: 5px;
}

.reg-capture-button,
.reg-retake-button {
  display: inline-block;
  margin: 5px;
}

.reg-take-button {
  font-size: 1rem;
}

.reg-retake-button,
.reg-capture-button {
  font-size: 1rem;
}


.reg-terms-dialog {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.reg-terms-content {
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 80%;
  text-align: center;
}

.reg-terms-content h2 {
  margin-top: 0;
}

.reg-terms-label {
  display: flex;
  align-items: center; 
  gap: 8px; 
  cursor: pointer;
  margin-left: 7rem; 
}

.reg-termsbox {
  margin: 0; 
  cursor: pointer; 
  border: 10px solid red;
}

.reg-terms-text {
  margin: 0; 
  cursor: pointer; 
}

.reg-terms-content button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.reg-terms-content button:hover {
  background-color: #0056b3;
}

.termslink{
  text-decoration: bold;
  margin-bottom: 5px;
    font-size: 1rem;
    color: #fff !important;
    text-align: start;
    font-family: 'Gellix' !important;
}

.give-away-reg-form-vdo{
  width: 70%;
  height: auto;
  object-fit: cover;
  border-radius: 25px;
}

@media (max-width: 1024px) {
  .reg-glass-container {
    width: 100%;
    max-width: 700px; 
    height: auto;
  }
  .reg-glass-form-group input {
    font-size: 1rem;
  }

  .reg-full-width-full-name,
  .reg-phone-input-group input select  {
    width: 100%;
    font-size: 1rem;
  }

  .reg-registeration-submit-button {
    width: 100%;
    max-width: 90%;
    font-size: 1rem;
  }

  .reg-terms-label {
    gap: 2rem;
  }

  
  .reg-preview-container {
    width: 100%;

    max-width: 20rem;
  }

  .reg-capture-button,
  .reg-retake-button {
    width: 100px; 
    font-size: 0.875rem;
  }
}

@media (max-width : 500px ){

  /* .termslink {
    font-size: 9px !important;
  }

  .agree-check-box{
    height: 10px;
  } */



  .reg-form-terms-condition-signature-container{
    display: flex;
  }

  .reg-checkandterms-flex-con {
    display: flex;    
  }

  #glass-registration-form {
    display: block;
    margin: 0 auto;
  }
  .reg-glass-container {
    width: 350px;
    padding: 2rem;
  }
  
  .reg-glass-form-group input {
    width: 18rem; 
    height: 2.4rem;
    font-size: 0.875rem; 
  }

  .reg-phone-input-group select {
    height: 2.5rem ;
    width: 5rem;
  }

  .reg-phone-input-group input {
    width: 21rem ;
  }

  .reg-registeration-submit-button {
    width: 100%;
    font-size: 0.875rem; 
  }
  
  .reg-terms-label {
    margin-left: 0; 
  }
  
  .reg-capture-button,
  .reg-retake-button {
    width: 80px;
    font-size: 0.75rem;
  }

  .reg-upload_image {
    height: 5rem;
    width: 8rem;
    text-align: center;
    align-items: center;
  }
  .reg-icon {
    font-size: 20px; 
   cursor: pointer;
    margin-top: -.5px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .reg-registeration-submit-button {
    margin-top: 1rem !important;
  }

.reg-icon:hover {
  font-size: 20px;
  cursor: pointer;

    margin-left: auto;
    margin-right: auto;
    display: block
}

.reg-signature-pad {
  border: 1px solid ; 
  height: 9rem;
  width: 15em;
}

.give-away-reg-form-vdo{
  width: 90% !important;
  height: auto !important;
  object-fit: cover;
  border-radius: 25px;
}
}





 .reg-registeration-submit-button {
    text-decoration: none !important;
    display: block;
    width: 75% !important ;
    padding: 15px 20px;
    margin: 20px auto;
    font-size: 1.2rem;
    color: #ffffff;
    background-color: #6000d9;
    border: none;
    border-radius: 50px;
    box-shadow: 0 8px 15px rgba(0, 123, 255, 0.2);
    transition: all 0.3s ease-in-out;
    text-align: center;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    margin-top:-1rem ;
  }

  .reg-registeration-submit-button {
    text-decoration: none !important;
  }
  
  .reg-registeration-submit-button:hover {
    background-color: #6000d9;
    box-shadow: 0 15px 20px rgba(0, 123, 255, 0.4);
    transform: translateY(-5px);
  }

  .reg-registeration-terms-close-btn {
    padding: 15px 20px;
    font-size: 1rem;
    color: #ffffff;
    background-color: #6000d9;
    border: none;
    border-radius: 50px;
    cursor: pointer;
  
  }

 

 /* .reg-form-terms-and-conditions-dialogbox-con { 
  background: url(../Images/BackgroundImages/backgroundImge.jpg) no-repeat center center fixed;
  background-size: cover; 

} */

.reg-form-terms-and-conditions-dialogbox-con::-webkit-scrollbar {
  border: none;
}

