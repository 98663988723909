.ifx_images_grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
    gap: 1rem; 
    padding: 1rem; 
  }
  .ifx_images_grid img {
    width: 100%;
    height: 100%;
    object-fit: cover; 
    border-radius: 1rem !important;
  }
  
  
  .ifx-image-one {
    grid-column: span 2; 
    grid-row: 1; 
  }
  
  .ifx-image-two {
    grid-column: span 1; 
    grid-row: 1;
  }
  
  .ifx-image-three {
    grid-column: span 1; 
    grid-row: 2;
  }
  
  .ifx-image-four {
    grid-column: span 1; 
    grid-row: 2;
  }
  
  .ifx-image-five {
    grid-column: span 1; 
    grid-row: 2;
  }
  
  .ifx-image-six {
    grid-column: span 1; 
    grid-row: 3;
  }
  
  .ifx-image-seven {
    grid-column: span 2; 
    grid-row: 3;
  }
  