.unauthorized-section {
    height: 100vh; /* Full viewport height */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #f8f9fa; /* Optional background color for better visibility */
  }
  
  .unauthorized-section h1 {
    font-size: 3rem;
    margin-bottom: 20px;
  }
  
  .unauthorized-section p {
    font-size: 1.2rem;
    color: #6c757d; /* Optional color */
  }
  