.dashboard-container {
    padding-top: 1em;
    padding-bottom: 10em;
    overflow: hidden;
  }
  
  .dashboard-button {
    text-decoration: none !important;
    display: block;
    width: 100%;
    padding: 15px 20px;
    margin: 20px auto;
    font-size: 1.2rem;
    color: #ffffff;
    background-color: #6000d9;
    border: none;
    border-radius: 50px;
    box-shadow: 0 8px 15px rgba(0, 123, 255, 0.2);
    transition: all 0.3s ease-in-out;
    text-align: center;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .button-link-page {
    text-decoration: none !important;
  }
  
  .dashboard-button:hover {
    background-color: #6000d9;
    box-shadow: 0 15px 20px rgba(0, 123, 255, 0.4);
    transform: translateY(-5px);
  }

  .admin-welcome-paragraph {
    color: #410087;
    font-size: 26px;
  }

  .login-admin-name {
    color: #6000D2;
    font-weight: bolder;
    font-size: larger;
  }
  
  .dashboard-button:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 300%;
    height: 300%;
    background-color: rgba(255, 255, 255, 0.1);
    transition: all 0.3s ease-in-out;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    transform: translate(-50%, -50%);
  }
  
  .dashboard-button:hover:before {
    opacity: 1;
    transform: translate(-50%, -50%) scale(0.8);
  }
  
.admin-header-name {
  font-size: 40px;
}


.button-link-page {
    position: relative;
    text-decoration: none;
}

.registeration-button-content {
    display: none;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 220px;
    background-color: #fff;
    color: #410087;
    text-align: center;
    border-radius: 6px;
    padding: 10px;
    z-index: 1;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    font-size: 16px;
    line-height: 1.4;
}

.button-link-page:hover .registeration-button-content {
    display: block;
}


.hover-info {
  display: none;
  background-color: #30363d61;
  color: white;
  padding: 10px 15px;
  position: absolute;
  top: 110%; /* Below the button */
  left: 50%;
  transform: translateX(-50%);
  border-radius: 15px;
  width: max-content;
  max-width: 220px;
  text-align: center;
  font-size: 14px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
  
  /* Tooltip Arrow */
  &::before {
    content: "";
    position: absolute;
    top: -5px; /* Positioned just above the tooltip */
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #fff transparent; /* Creates the arrow */
  }
}

.button-link-page:hover .hover-info {
  display: block;
}

.dashboard-button:hover {
  background-color: #6000d9;
  box-shadow: 0 15px 20px rgba(0, 123, 255, 0.4);
  transform: translateY(-5px);
}


.logout-icon {
  font-size: 30px;
  color: #410087;
  transition: color 0.3s ease, transform 0.3s ease;
}

.logout-icon:hover {
  color: #c82333;
  transform: scale(1.1);
}



  /* Logout Confirmation Modal */
  .modal-header {
    background-color: #f8d7da;
    border-bottom: none;
  }
  
  .modal-title {
    color: #dc3545;
    font-weight: bold;
  }
  
  .modal-body p {
    font-size: 16px;
    color: #333;
  }
  
  


  @media (min-width: 768px) {
    .dashboard-button {
      width: auto;
    }
  
    .row.justify-content-center {
      justify-content: center !important;
    }
    
  }
  
  @media (min-width: 992px) {
    .row.justify-content-center {
      justify-content: center !important;
    }
  }
  