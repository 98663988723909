/* Container styling */
.login-secttion{
    height: 100vh;
    overflow: hidden;
}


.login-secttion::-webkit-scrollbar {
    display: none !important;
}

.login-secttion-inner {
    scrollbar-width: none !important; 
    -ms-overflow-style: none;
}

.login-secttion-inner::-webkit-scrollbar {
    display: none  !important; 
}
/* Box styling */
.login-box {
   box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); 
  background: #30363d61;
  border: 2px solid rgba(255, 255, 255, 1); 
    border-radius: 10px;
    padding: 40px;
    text-align: center;
    width: 100%;
    max-width: 600px;
}


.login-title {
    color: #000;
    margin-bottom: 20px;
    font-weight: 600;
    font: bolder;
    font-family: 'Gellix' !important;
}

.login-form {
    width: 100%;
}

/* Input group styling */
.login-input-group {
    margin-bottom: 20px;
    position: relative;
}

/* Label styling */
.login-label {
    display: block;
    margin-bottom: 8px;
    color: white;
    font-size: 18px;
    text-align: left;
}

.login-name,
.login-pwd {
    width: 100%;
    padding: 15px;
    border: none;
    outline: none;
    font-size: 16px;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.2);
    color: white;
}

.login-name::placeholder,
.login-pwd::placeholder {
    color: rgba(255, 255, 255, 0.7);
}

.login-toggle-password {
    position: absolute;
    right: 30px;
    top: 69%;
    transform: translateY(-50%);
    cursor: pointer;
    color: #fff;
}

.login-button {
    background: #fea709;
    color: #000;
    padding: 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 18px;
    width: 100%;
    transition: background 0.3s ease;
}

.login-button:hover {
    background: #6000d2;
    color: #fff;

}

@media (max-width: 768px) {
    .login-box {
        width: 90%;
    }

    .login-title {
        font-size: 1.5rem;
    }

    .login-button {
        font-size: 16px;
    }
}
