.App{
  background-image: url(./Components/Images/BackgroundImages/backgroundImge.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}





.navigate-btns{
  border: none !important;
  border-radius: 30px !important;
  font-size: 0.9rem !important;
  padding: 0.8rem 2rem !important;
  transition: all 0.3s ease-in-out !important;
  display: inline-block !important;
  cursor: pointer !important;
  background: #6000d9 !important;
color: white !important;
box-shadow: 0 8px 15px rgba(0, 123, 255, 0.2);
font-family: 'Gellix' !important;
}

.navigate-btns:hover{
  background-color: #6000d9 ;
    box-shadow: 0 15px 20px rgba(0, 123, 255, 0.4);
    transform: translateY(-5px) ;
}

.pagination-btn{
  border: none !important;
  border-radius: 30px !important;
  font-size: 0.9rem !important;
  transition: all 0.3s ease-in-out !important;
  display: inline-block !important;
  cursor: pointer !important;
  background: #6000d9 !important;
color: white !important;
box-shadow: 0 8px 15px rgba(0, 123, 255, 0.2);
font-family: 'Gellix' !important;

}

.pagination-btn:hover {
  background-color: #6000d9 !important; /* Darken background on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15) !important; /* Enhance shadow on hover */
}

.pagination-btn:focus {
  background-color: #6000d9 !important;
  outline: none !important; /* Remove default outline */
  box-shadow: 0 0 0 4px rgba(96, 0, 217, 0.4) !important; /* Add custom focus effect */
  border: none !important;
}

.pagination-btn .MuiSelect-icon {
  color: white !important; /* Ensure icon matches the text color */
}

.css-1t7lzbz-MuiTableCell-root{
  border-bottom: none !important;
}

.css-10ukr6t-MuiTableCell-root{
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  /* border-bottom : #464f5a61 !important; */
}

/* 

.css-10ukr6t-MuiTableCell-root{
  border-bottom: none !important;
  border-top: none !important;
  
}




.table-row{
  background: #30363d61;
    color: white !important;
    font-family: "Gellix";
    font-weight: bold;
    font-size: 0.5rem;
}

.table-cell-value{
  color: white !important;
  height: 50px !important;
} */


.table-row{
  background: #ffffff61;
    color: white !important;
    font-family: "Gellix";
    font-weight: bold;
    font-size: 0.5rem;
    border-top-left-radius: 700px !important;
}

.table-container {
  border-radius: 25px; /* Adjust the radius as needed */
  overflow: hidden; /* Ensures that content stays inside the rounded border */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow for a nice effect */
}

/* .css-10ukr6t-MuiTableCell-root{
  border-bottom: 1px solid #6000d2 !important;
} */

@media (max-width: 480px){
  .pagination-btn{
    border: none !important;
    border-radius: 30px !important;
    padding: 0.8rem 2rem !important;
    font-size: 0.9rem !important;
    transition: all 0.3s ease-in-out !important;
    display: inline-block !important;
    cursor: pointer !important;
    background: #6000d9 !important;
  color: white !important;
  box-shadow: 0 8px 15px rgba(0, 123, 255, 0.2);
  font-family: 'Gellix' !important;
  }

  .reg-page-about-paragraph{
    text-align: justify !important;
  }
}


