.leader-board-routing-section{
    /* height: 120vh; */
    overflow: hidden !important;
}

.leader-board-timer-container{
    background-color: #6000d2;
    color: white;
    padding: 10px 10px;
    font-family: 'Gellix' !important;
    border-radius: 16px;
}

.leader-board-footer-container {
    background-color: #6000d2;
    color: white;
    padding: 10px;
    font-family: 'Gellix' !important;
    overflow: hidden !important;
    position: fixed;
    bottom: 0;
    width: 100%;
  }
  
  .marquee-item {
    display: flex;
    align-items: center;
    gap: 15px; /* Space between image and text */
    margin-right: 100px; /* Space between the sets */
  }
  
  .leader-board-footer-kama-icon {
    height: 30px;
  }
  
  .leader-board-footer-text {
    font-size: 2vh;
    white-space: nowrap;
  }