.reg-footer {
    background: #231f20;
    padding: 1rem;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    background-color: rgba(35, 31, 32, 0.8);
}

.reg-footer-content {
    color: white;
    font-size: 1rem;
    display: flex;
    flex-wrap: wrap; 
    justify-content: space-between; 
    

}

.reg-footer-logo {
    height: 30px;
}

.reg-footer-content a {
    text-decoration: none;
    color: white;
    font-size: 1rem;
}

.footer-item {
    display: flex;
    align-items: center;
    gap: 10px; 
}

@media screen and (max-width: 992px) {
    .reg-footer-content {
        font-size: 1.1rem;
        flex-direction: row; 
        justify-content: space-between; 
        text-align: center; 
    }

    .reg-footer-logo {
        height: 50px;
    }
}

@media screen and (max-width: 576px) {

    .reg-footer  {
        margin: auto 0 !important;
        gap: 5rem;
    }

    
    .reg-footer-content {
        font-size: 10px !important;
        flex-direction: row; 
    }

    .reg-footer-logo {
        height: 25px;
    }

    .reg-footer-content a {
        font-size: .8rem !important
    }
}

@media screen and (max-width: 350px) {
    .reg-footer-content a {
        font-size: .65rem !important
}
}