.registration-index-section{
  padding-top: 5em !important;
}
.search-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  position: relative;
}

.input-container {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 400px;
  position: relative;
}

.input-container input[type="text"] {
  width: 100%;
  padding: 10px 40px 10px 15px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 25px;
  outline: none;
  transition: all 0.3s ease;
}

.input-container input[type="text"]:focus {
  border-color: #fea701;
  box-shadow: 0 0 5px rgba(254, 167, 1, 0.5);
}

.input-container .searchicon {
  position: absolute;
  right: 15px;
  color: #ccc;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.input-container input[type="text"]:focus + .searchicon {
  color: #fea701;
}



.admin-edit-registeration-indexpage {
  background: url(../Images/BackgroundImages/backgroundImge.jpg) no-repeat center center fixed;
  background-size: cover; 
  
}

.admin-edit-registeration-indexpage::-webkit-scrollbar {
  display: none;
}

.admin-reg-indexpage-viewinfo {
  background-color: #0000;
}



.admin-edit-registeration-indexpage-close-btn {
    padding: 15px 20px;
    font-size: 1rem;
    color: #ffffff;
    background-color: #6000d9 !important;
    border: none;
    border-radius: 50px;
    box-shadow: 0 8px 15px rgba(0, 123, 255, 0.2);
    transition: all 0.3s ease-in-out;
    text-align: center;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}






