.register-unique-id-generated {
   
    height: 100vh;
    width: 100vw;
    background: url(../Images/BackgroundImages/backgroundImge.jpg) no-repeat center center fixed; 
    background-size: cover;
  }
  
  .register-unique-id-generated-box {
  
     background: rgba(35,31,32, 0.365);
    backdrop-filter: blur(10px); 
    border-radius: 10px; 
    padding: 20px; 
    margin: 100px auto;
    

    border: 1px solid rgba(255, 255, 255, 0.2); 
  }

  .registeration-show-unique-id {
    background: #fffffff5;
    backdrop-filter: blur(10px); 
    border-radius: 10px; 
    display: block;
    border: 5px solid red;
    border: 1px solid rgba(255, 255, 255, 0.2); 
  }


  .unique-id-button {
    text-decoration: none !important;
    display: block;
    padding: 15px 20px;
    font-size: 1.2rem;
    color: #ffffff;
    background-color: #6000d9;
    border: none;
    border-radius: 50px;
    box-shadow: 0 8px 15px rgba(0, 123, 255, 0.2);
    transition: all 0.3s ease-in-out;
    text-align: center;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  
  .unique-id-button:hover {
    background-color: #6000d9;
    box-shadow: 0 15px 20px rgba(0, 123, 255, 0.4);
    transform: translateY(-5px);
  }
  