/* @font-face {
    font-family: 'Gellix';
    src: url('./Assests/font/Gellix-Regular.woff2')format('woff2'),
         url('./Assests/font/Gellix-Bold.woff')format('woff');
    font-weight: normal;
    font-style: normal;
}

body {
  margin: 0;
  font-family: 'Gellix' !important ;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Uncomment if you want to apply a specific font to code blocks */
/*
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
} */


@font-face {
    font-family: 'Gellix';
    src: url(./Assests/font/Gellix-Regular.woff2) format('woff2'),
         url(./Assests/font/Gellix-ExtraBold.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}


body {
  margin: 0;
  padding: 0;
  font-family: 'Gellix' !important;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}