.star-rating {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0;
  }
  
  .star-row {
    display: flex;
    justify-content: center;
    margin: 10px 0;
  }
  
  .star {
    font-size: 50px;
    cursor: pointer;
    transition: color 0.3s ease-in-out, transform 0.2s;
    margin: 0 10px;
    text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  .star.filled {
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);
  }
  
  .star:hover {
    transform: scale(1.2);
    transition: transform 0.2s ease-in-out;
  }
  
 
  